var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(52, "Cost center") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.budget.costCenter, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(75, "Name") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.budget.description, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(53, "Type") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.typeValue, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(54, "Budget amount") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.budget.initialBudget,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c("currency-svg", {
                                attrs: {
                                  color: "#06263E",
                                  opacity: "0.85",
                                  width: "15",
                                  height: "15",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(55, "Estimated final cost (EFC)"),
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.budget.estimatedFinalCost,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c("currency-svg", {
                                attrs: {
                                  color: "#06263E",
                                  opacity: "0.85",
                                  width: "15",
                                  height: "15",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(165, "Department") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.budget.departmentName,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c("currency-svg", {
                                attrs: {
                                  color: "#06263E",
                                  opacity: "0.85",
                                  width: "15",
                                  height: "15",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }