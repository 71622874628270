var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "6", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(152, "Consumption date") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.consumptionDateDisplay,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            { staticClass: "cursor-pointer" },
                            [
                              _c(_vm.getLucideIcon("Calendar"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 16 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "6", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(57, "Type 1") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.labelForType1, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "6", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(58, "Type 2") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.labelForType2, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(75, "Country") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.labelForCountry, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "6", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(60, "Filming area") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: { value: _vm.valueForFilmingArea, disabled: "" },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(259, "m2")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "6", md: "3", lg: "3", xl: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(69, "Filming days") } },
                [
                  _c("b-form-input", {
                    staticClass: "bg-color-grams-hair",
                    attrs: { value: _vm.valueForFilmingDays, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }