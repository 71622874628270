<template>
	<errorContainer :error="error">
		<transition name="slide">
			<div style="position: relative">
				<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
					<div class="form">
						<b-row>
							<b-col>
								<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
									{{ FormMSG(18, 'Green table') }}
								</h1>
							</b-col>
						</b-row>
						<div ref="containerGreenList">
							<b-row class="mt-3">
								<b-col class="p-0">
									<b-card no-body>
										<b-card-text>
											<b-row>
												<b-col>
													<b-alert :show="projectEnded" variant="warning">
														<component :is="getLucideIcon('AlertTriangle')" :size="16" />
														{{ FormMSG(841, 'Project end date has passed ') }} ({{ endDateProject | formatDate('DD-MM-YYYY') }})
													</b-alert>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<filter-budget-advanced
														hide-expense-details-section
														hide-supplier-section
														:hide-entry-detail-section="false"
														hide-button-export
														hide-button-refresh-data
														:label-expense-details="FormMSG(148, 'Expense details')"
														:filter-type="5"
														@filter-budget-advanced:have-filter="handlerFilterBudgetAdvancedHaveFilter"
													/>
												</b-col>
											</b-row>
											<b-row v-if="carbonTemplateExist === true">
												<b-col>
													<div class="float-right">
														<b-button
															variant="custom-primary-blue"
															:disabled="loadingRunAutomation"
															@click="handleClickRunAutomation"
														>
															<b-spinner v-if="loadingRunAutomation" small class="mr-3" />
															<component :is="getLucideIcon('Play')" :size="16" />
															{{ FormMSG(197, 'Run automations') }}
														</b-button>
													</div>
												</b-col>
											</b-row>
											<b-row>
												<b-col>
													<div class="d-flex row align-items-center mt-3 hide-on-tablet">
														<b-col md="5">
															<b-form-group class="mb-0">
																<b-input-group v-if="$screen.width >= 992">
																	<b-form-input
																		v-model="filterInputModel"
																		type="text"
																		id="filterInput"
																		:placeholder="this.FormMSG(37, 'Type to Search')"
																		@change="handleChangeFilter"
																		@keyup.enter="handleKeydowEnterFilter"
																	/>
																	<b-input-group-append class="cursor-pointer" id="groupAppendSearch">
																		<b-input-group-text class="btn-search">
																			<component
																				:is="getLucideIcon('Search')"
																				color="#FFFFFF"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				v-if="filter.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#FFFFFF"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="handleResetFilter"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-form-group>
														</b-col>
														<b-col md="7">
															<div class="d-flex justify-content-between">
																<div class="text-color-australien fs-16 fw-600">
																	<div class="d-flex">
																		<div>
																			<component :is="getLucideIcon('AlertTriangle')" :size="20" :stroke-width="2.5" />
																		</div>
																		<div class="ml-2 pt-1">
																			0 - {{ pagination.offset }} {{ FormMSG(519, 'on') }} {{ totalRecord }}
																			{{ FormMSG(520, 'records') }}
																		</div>
																	</div>
																</div>
																<div>
																	<b-button
																		v-if="projectEnded === false"
																		variant="primary"
																		class="mr-2"
																		@click="handleClickNewEntry"
																	>
																		<component :is="getLucideIcon('Plus')" :size="16" />
																		{{ FormMSG(547, 'New entry') }}
																	</b-button>

																	<b-button
																		variant="custom-primary-blue"
																		:disabled="greenListToReview.length === 0"
																		@click="handleClickReviewSelection"
																	>
																		<component :is="getLucideIcon('Check')" :size="16" />
																		{{ FormMSG(548, 'Review selection') }}
																	</b-button>
																</div>
															</div>
														</b-col>
													</div>
												</b-col>
											</b-row>
											<b-row class="mt-3">
												<b-col id="containerGreenTable" ref="containerGreenTable">
													<b-table
														v-if="$screen.width >= 992"
														selected-variant="primary"
														hover
														selectable
														select-mode="single"
														responsive="sm"
														ref="documentPackageTable"
														sticky-header="700px"
														:items="greenList"
														style="text-align: left"
														:fields="greenTableFields"
														bordered
														striped
														small
														head-variant="dark"
														:empty-text="FormMSG(250, 'No data found')"
														show-empty
														tbody-tr-class="p-2"
													>
														<template #head(actionSelected)="data">
															<b-form-checkbox
																v-if="greenList.length > 0"
																v-model="selectAll"
																size="lg"
																@change="handleInputSelectAll"
															/>
														</template>
														<template #cell(verified)="{ item, index }">
															<div class="wrap-status">
																<div
																	class="status fw-700"
																	:class="{
																		validated: item.verified === 3,
																		'not-submitted': item.verified === 0,
																		pending: item.verified === 2,
																		info: item.verified === 1
																	}"
																	style="font-size: 0.7rem"
																>
																	<div class="text-center">
																		{{ getLabelVerifiedStatus(item.verified) }}
																	</div>
																</div>
															</div>
														</template>
														<template #cell(actionSelected)="data">
															<b-form-checkbox
																v-model="data.item.checked"
																size="lg"
																@change="handleInputItemChecked($event, data.item, data.index)"
															/>
														</template>
														<template #cell(date)="{ item }">
															<div>
																{{ item.date | formatDate('DD/MM/YYYY') }}
															</div>
														</template>
														<template #cell(amount)="{ item }">
															<div>
																{{ item.amount | formatCurrency('DD/MM/YYYY') }}
															</div>
														</template>
														<template #cell(coTwo)="{ item }">
															<div>
																{{ item.coTwo | formatRendKg('DD/MM/YYYY') }}
															</div>
														</template>
														<template #cell(option)="{ item, index }">
															<div class="d-flex justify-content-center" style="min-width: 60px">
																<b-dropdown
																	id="dropdown-green-action"
																	no-caret
																	dropleft
																	boundary="window"
																	class="btn-transparent"
																	variant="none"
																	size="sm"
																	lazy
																>
																	<template #button-content>
																		<component :is="getLucideIcon('MoreVertical')" :size="16" />
																	</template>
																	<b-dropdown-item @click="handleClickReview(item, index)">
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('CheckCircle')" :size="16" />&nbsp;{{
																				FormMSG(259, 'Review')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item
																		v-if="showResetStatusMenu(item) === true"
																		@click="handleClickResetStatus(item, index)"
																	>
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('RotateCcw')" :size="16" />&nbsp;{{
																				FormMSG(289, 'Reset status')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item
																		v-if="item.templateId > 0"
																		@click="handleClickViewAutomation(item.templateId)"
																	>
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('Regex')" :size="16" />&nbsp;{{
																				FormMSG(291, 'View automation')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item @click="showPicture(+item.id)">
																		<span class="text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('FileImage')" :size="16" />&nbsp;{{
																				FormMSG(252, 'See picture')
																			}}
																		</span>
																	</b-dropdown-item>
																	<b-dropdown-item v-if="item.entityType === 8" @click="handleClickDeleteExternal(item)">
																		<span class="text-color-burning-tomato">
																			<component :is="getLucideIcon('Trash2')" :size="16" />&nbsp;{{
																				FormMSG(260, 'Remove')
																			}}
																		</span>
																	</b-dropdown-item>
																</b-dropdown>
															</div>
														</template>
													</b-table>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="6">
													<div class="d-flex">
														<b-button
															size="md"
															variant="custom-outline-gray"
															class="mr-2"
															:disabled="deleteDisabled"
															@click="handleClickDeleteExternalSources"
														>
															{{ FormMSG(158, 'Delete') }}
														</b-button>
														<b-dropdown
															id="dropdown-action-selection"
															no-caret
															variant="outline-primary"
															:disabled="greenListToReview.length === 0 || loadingActionSelection"
															lazy
														>
															<template #button-content>
																<div class="d-flex">
																	<div v-if="loadingActionSelection">
																		<b-spinner small />
																	</div>
																	<div>
																		{{ FormMSG(562, 'Change selection status ...') }}
																	</div>
																</div>
															</template>
															<b-dropdown-item @click="resetStatusSelection">
																<div class="d-flex">
																	<div class="mr-2">
																		<component :is="getLucideIcon('RotateCcw')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(612, 'Reset selection status') }}
																	</div>
																</div>
															</b-dropdown-item>
															<b-dropdown-item @click="setStatusToReviewSelection">
																<div class="d-flex text-color-rhapsody-in-blue">
																	<div class="mr-2">
																		<component :is="getLucideIcon('UserCheck')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(613, 'Mark selection as TO REVIEW') }}
																	</div>
																</div>
															</b-dropdown-item>
															<b-dropdown-item @click="handleClickMarkReviewedSelections">
																<div class="d-flex text-color-rhapsody-in-blue">
																	<div class="mr-2">
																		<component :is="getLucideIcon('Check')" :size="18" />
																	</div>
																	<div>
																		{{ FormMSG(614, 'Mark selection as REVIEWED') }}
																	</div>
																</div>
															</b-dropdown-item>
														</b-dropdown>
														<!--														<b-button-->
														<!--															size="md"-->
														<!--															variant="blue-sapphire"-->
														<!--															:disabled="greenListToReview.length === 0"-->
														<!--															@click="handleClickMarkReviewedSelections"-->
														<!--														>-->
														<!--															<component v-if="loadingForMarkReview === false" :is="getLucideIcon('CheckCircle')" :size="16" />-->
														<!--															<b-spinner v-if="loadingForMarkReview === true" small />-->
														<!--															{{ FormMSG(755, 'Mark as reviewed selection') }}-->
														<!--														</b-button>-->
													</div>
												</b-col>
											</b-row>
										</b-card-text>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>

					<review-dialog
						:open="showReviewDialog"
						:for-create="createNewGreen"
						:source="sourceType"
						:data-to-review="dataToReview"
						:data-length="lengthDataToReview"
						:current-index="currentIndexDataToReview"
						@review-dialog:before="handleReviewDialogBefore"
						@review-dialog:after="handleReviewDialogAfter"
						@review-dialog:submit="handleReviewDialogSubmit"
						@review-dialog:update-success="handleReviewDialogUpdateSuccess"
						@review-dialog:reviewed="handleReviewDialogReviewed"
						@review-dialog:automation-end="handleReviewDialogAutomationEnd"
						@review-dialog:reset-status="handleReviewDialogResetStatus"
						@review-dialog:close="handleReviewDialogClose"
					/>

					<carbon-template-dialog
						:open="showCarbonTemplateDialog"
						:data-to-edit="carbonTemplateData"
						@carbon-template-dialog:close="handleCarbonTemplateDialogClose"
					/>

					<view-log-automation-dialog :open="showViewLogAutomation" :log-data="logAutomation" @view-log-automation:close="handleViewLogAutomation" />

					<run-automation-option-dialog
						:open="showRunAutomationOption"
						@run-automation-option-dialog:close="showRunAutomationOption = false"
						@run-automation-option-dialog:choice="handleRunAutomationOptionDialogChoice"
					/>

					<new-entry-option-dialog
						:open="showNewEntryOptionDialog"
						@new-entry-option:close="showNewEntryOptionDialog = false"
						@new-entry-option:create="handleNewEntryOptionCreate"
						@new-entry-option:success="handleNewEntryOptionSuccess"
					/>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { mapActions, mapGetters } from 'vuex';
import { store } from '@/store';
import BudgetMixin from '@/components/Budget/budget.mixin';

import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import CustomPagination from '@/components/Budget/CustomPagination';
import ReviewDialog from '@/modules/carbonclap/components/green/ReviewDialog';

import {
	getProjectCarbon,
	delCarbonFromExternalSource,
	getTotalNumberProjectCarbon,
	checkIfCarbonTemplateExist,
	runAutomation,
	changeCarbonVerifiedStatus,
	getCarbonTemplate,
	getCarbonFiles
} from '@/cruds/carbon.crud';
import { rendCurrency, rendKgCo2, getFileExtension, getFileName } from '~helpers';

import _ from 'lodash';
import moment from 'moment';
import NewEntryOptionDialog from '@/modules/carbonclap/components/green/NewEntryOptionDialog.vue';
import ViewLogAutomationDialog from '@/modules/carbonclap/components/carbon-template/ViewLogAutomationDialog.vue';
import { CARBON_VERIFIED_STATUS_MENU } from '@/shared/constants';
import RunAutomationOptionDialog from '@/modules/carbonclap/components/carbon-template/RunAutomationOptionDialog.vue';
import CarbonTemplateDialog from '@/modules/carbonclap/components/carbon-template/CarbonTemplateDialog.vue';

const LIMIT = 100;

export default {
	name: 'greenList',

	mixins: [languageMessages, globalMixin, BudgetMixin],

	components: {
		FilterBudgetAdvanced,
		CustomPagination,
		ReviewDialog,
		NewEntryOptionDialog,
		ViewLogAutomationDialog,
		RunAutomationOptionDialog,
		CarbonTemplateDialog
	},

	data() {
		return {
			error: {},

			filter: '',
			offset: 0,
			limit: _.cloneDeep(LIMIT),
			perPage: 100,

			greenList: [],
			greenListToReview: [],
			totalRecord: 0,

			filterActive: {
				startDate: store.state.myProject.startDate,
				endDate: store.state.myProject.endDate,
				userDepartment: -1
			},
			getIndexFromListToReview: false,

			selectAll: false,
			showReviewDialog: false,
			createNewGreen: false,
			sourceType: 1,
			dataToReview: {},
			currentIndexDataToReview: 0,
			lengthDataToReview: 0,

			prepareLoading: false,
			showNewEntryOptionDialog: false,

			carbonTemplateExist: false,
			loadingRunAutomation: false,
			logAutomation: {},
			showViewLogAutomation: false,

			filterInputModel: '',

			loadingForMarkReview: false,
			alreadyLastData: false,

			showRunAutomationOption: false,
			showCarbonTemplateDialog: false,
			carbonTemplateData: {},

			haveFilter: false,

			loadingActionSelection: false,
      pagination: {
        offset: 0,
        totalRecords: 0
      }
		};
	},

	computed: {
		...mapGetters({
			filterBudget: 'global-filter/filterBudget',
			listFilters: 'global-filter/listFilters',
			paginationFilter: 'global-filter/paginationFilter'
		}),

		deleteDisabled() {
			let result = true;

			for (let i = 0; i < this.greenListToReview.length; i++) {
				const element = this.greenListToReview[i];
				if (element.entityType === 8) {
					result = false;
					break;
				}
			}

			return result;
		},

		projectEnded() {
			const endProject = new Date(store.state.myProject.endDate);
			if (endProject.getTime() < new Date().getTime()) {
				return true;
			}

			return false;
		},

		endDateProject() {
			return store.state.myProject.endDate;
		},

		filterGreenTable() {
			if (!_.isNil(this.filterBudget.forGreenTable)) {
				const payload = this.filterBudget.forGreenTable;
				let startDate = '';
				let endDate = '';
				if (!payload.startDate && !payload.endDate) {
					// startDate = store.state.myProject.startDate;
					// endDate = store.state.myProject.endDate;
					startDate = null;
					endDate = null;
				}
				if (payload.startDate && !payload.endDate) {
					// startDate = payload.startDate;
					// endDate = store.state.myProject.endDate;
					startDate = payload.startDate;
					endDate = null;
				}
				if (!payload.startDate && payload.endDate) {
					// startDate = store.state.myProject.startDate;
					// endDate = payload.endDate;
					startDate = null;
					endDate = payload.endDate;
				}
				if (payload.startDate && payload.endDate) {
					startDate = payload.startDate;
					endDate = payload.endDate;
				}

				const filterToReturn = this.returnFilter(this.filterBudget.forGreenTable);
				return {
					...filterToReturn,
					startDate,
					endDate
				};
			}
			return {
				startDate: null,
				endDate: null,
				departmentId: -1
			};
		},

		paginationGreenTable() {
			return this.paginationFilter.forGreenTable;
		},

		greenTableFields() {
			let result = [
				{
					key: 'actionSelected',
					label: '',
					class: 'text-center actionSelected',
					sortable: false
				},
				{
					key: 'strDate',
					label: this.FormMSG(165, 'Date'),
					formatter: (value) => {
						return moment(new Date(value.split('T')[0])).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'w-75-px'
				},
				{
					key: 'entityType',
					label: this.FormMSG(200, 'Source'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(11006, parseInt(value, 10));
					},
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(201, 'Description'),
					sortable: true
				},
				{
					key: 'coTwoCategory',
					label: this.FormMSG(202, 'CO2 type'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1334, parseInt(value, 10));
					},
					sortable: true
				},
				{
					key: 'supplier.name',
					label: this.FormMSG(203, 'Supplier'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(204, 'Full Name'),
					formatter: (value, key, item) => {
						return item.user.name + ' ' + item.user.firstName;
					},
					sortable: true
				},
				{
					key: 'costCenter',
					label: this.FormMSG(267, 'Cost center')
				},
				{
					key: 'verified',
					label: this.FormMSG(285, 'Already reviewed?'),
					formatter: (value) => {
						return value === true ? this.FormMSG(425, 'Yes') : this.FormMSG(426, 'No');
					},
					class: 'text-center',
					sortable: true
				},
				{
					key: 'amount',
					label: this.FormMSG(207, 'Amount'),
					formatter: (value) => {
						return rendCurrency(parseFloat(value));
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'carbonValues.kgCoTwo',
					label: this.FormMSG(208, 'CO2'),
					formatter: (value, key, item) => {
						return rendKgCo2(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'option',
					label: this.FormMSG(209, 'Option'),
					sortable: false,
					class: 'text-center w-75-px'
				}
			];

			return result;
		}
	},

	async mounted() {
		this.$bus.$on('filter-budget-advanced:change', async (payload) => {
			await this.handleFilterBudgetAdvancedChange(payload);
		});

		this.$bus.$on('filter-budget-advanced:clear-filters', async (payload) => {
			await this.handleFilterBudgetAdvancedChange({});
		});

		await this.handleScroll();
	},

	destroyed() {
		clearTimeout();
	},

	async created() {
		await this.getMyFilterActive(5);
		await this.getListFilters(5);
		await this.checkIfCarbonTemplateExist();

		await this.getGreenTableList();
	},

	methods: {
		...mapActions({
			getListFilters: 'global-filter/getListFilters',
			getMyFilterActive: 'global-filter/getMyFilterActive'
		}),

		async handleReviewDialogAutomationEnd({ carbonData }) {
			for (let i = 0; i < this.greenList.length; i++) {
				if (+this.greenList[i].id === +carbonData.id) {
					this.greenList[i].verified = carbonData.verified;
					break;
				}
			}
		},

		handlerFilterBudgetAdvancedHaveFilter({ haveFilter }) {
			this.haveFilter = haveFilter;
		},

		async handleReviewDialogResetStatus({ carbonData }) {
			for (let i = 0; i < this.greenList.length; i++) {
				if (+this.greenList[i].id === +carbonData.id) {
					this.greenList[i].verified = carbonData.verified;
					break;
				}
			}
		},

		showResetStatusMenu(item) {
			if ((item.entityType === 1 || item.entityType === 8) && item.verified > 0) {
				return true;
			} else if ([2, 3, 4, 5, 6, 7, 9, 10].includes(item.entityType) && item.verified !== 2) {
				return true;
			}

			return false;
		},

		async handleClickResetStatus(item, index) {
			let reviewStatusToSend = 0;
			const carbonId = +item.id;

			if (item.entityType === 1) {
				/*
				if (item.coTwoCategory === 4) {
					reviewStatusToSend = 2;
					this.greenList[index].verified = 2;
				} else { */
				reviewStatusToSend = 0;
				this.greenList[index].verified = 0;
				this.greenList[index].coTwoCategory = 0;
				//	}
			} else if (item.entityType === 8) {
				reviewStatusToSend = 0;
				this.greenList[index].verified = 0;
				this.greenList[index].coTwoCategory = 0;
			} else if ([2, 3, 4, 5, 6, 7, 9, 10].includes(item.entityType)) {
				reviewStatusToSend = 2;
				this.greenList[index].verified = reviewStatusToSend;
			}

			await changeCarbonVerifiedStatus([+carbonId], reviewStatusToSend);

			this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(314, 'Updated status successfully!'));
		},

		async setStatusToReviewSelection() {
			const action = async () => {
				try {
					this.loadingActionSelection = true;

					let carbonIds = [];

					for (let i = 0; i < this.greenListToReview.length; i++) {
						const item = this.greenListToReview[i];

						carbonIds.push(+item.id);
					}

					await changeCarbonVerifiedStatus(carbonIds, 2);

					for (let i = 0; i < this.greenList.length; i++) {
						const item = this.greenList[i];

						if (carbonIds.length === 0) {
							break;
						}

						let findIndex = carbonIds.findIndex((option) => option === +item.id);

						if (findIndex > -1) {
							item.verified = 2;

							carbonIds.splice(findIndex, 1);
						}
					}

					this.loadingActionSelection = false;
					this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(389, 'Updated status of selection successfully!'));
				} catch (e) {
					this.loadingActionSelection = false;
					this.error = e;
				}
			};

			this.confirmModal(action, this.FormMSG(798, "Confirm changing status of selected items to 'TO REVIEW'?"));
		},

		async resetStatusSelection() {
			const action = async () => {
				try {
					this.loadingActionSelection = true;

					let expenseCarbonIds = [];
					let externalCarbonIds = [];
					let othersCarbonIds = [];

					for (let i = 0; i < this.greenListToReview.length; i++) {
						const item = this.greenListToReview[i];

						if (item.entityType === 1) {
							expenseCarbonIds.push(+item.id);
						} else if (item.entityType === 8) {
							externalCarbonIds.push(+item.id);
						} else if ([2, 3, 4, 5, 6, 7, 9, 10].includes(item.entityType)) {
							othersCarbonIds.push(+item.id);
						}
					}

					if (expenseCarbonIds.length > 0) {
						await changeCarbonVerifiedStatus(expenseCarbonIds, 0);
					}

					if (externalCarbonIds.length > 0) {
						await changeCarbonVerifiedStatus(externalCarbonIds, 0);
					}

					if (othersCarbonIds.length > 0) {
						await changeCarbonVerifiedStatus(othersCarbonIds, 2);
					}

					let concatIds = _.concat([], expenseCarbonIds, externalCarbonIds, othersCarbonIds);

					for (let i = 0; i < this.greenList.length; i++) {
						const item = this.greenList[i];

						if (concatIds.length === 0) {
							break;
						}

						let findIndex = concatIds.findIndex((option) => option === +item.id);

						if (findIndex > -1) {
							if (item.entityType === 1) {
								item.verified = 0;
								item.coTwoCategory = 0;
							} else if (item.entityType === 8) {
								item.verified = 0;
								item.coTwoCategory = 0;
							} else if ([2, 3, 4, 5, 6, 7, 9, 10].includes(item.entityType)) {
								item.verified = 2;
							}

							concatIds.splice(findIndex, 1);
						}
					}

					this.loadingActionSelection = false;
					this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(389, 'Updated status of selection successfully!'));
				} catch (e) {
					this.error = e;
				}
			};

			this.confirmModal(action, this.FormMSG(194, 'Are you sure to reset status of selection ?'));
		},

		async handleClickViewAutomation(templateId) {
			this.carbonTemplateData = await getCarbonTemplate(+templateId);

			this.showCarbonTemplateDialog = true;
		},

		handleCarbonTemplateDialogClose() {
			this.showCarbonTemplateDialog = false;
			this.carbonTemplateData = {};
		},

		async handleRunAutomationOptionDialogChoice({ choice }) {
			await this.runAutomationAction(choice);
		},

		getLabelVerifiedStatus(value) {
			let menues = this.FormMenu(CARBON_VERIFIED_STATUS_MENU);
			return menues.find((option) => option.value === value).text;
		},

		async handleScroll() {
			setTimeout(() => {
				const element = document.querySelector('#containerGreenTable .b-table-sticky-header');

				element.addEventListener('scroll', async () => {
					const scrollTop = element.scrollTop;
					const scrollHeight = element.scrollHeight;
					const clientHeight = element.clientHeight;

					if (scrollTop + clientHeight >= scrollHeight) {
						if (this.alreadyLastData === false) {
							this.offset += this.limit;
							await this.getGreenTableList(true, 'containerGreenTable');
						}
					}
				});
			}, 1000);
		},

		async handleClickMarkReviewedSelections() {
			try {
				const action = async () => {
					try {
						this.loadingActionSelection = true;
						let listId = [];
						for (let i = 0; i < this.greenListToReview.length; i++) {
							listId.push(+this.greenListToReview[i].id);
						}
						await changeCarbonVerifiedStatus(listId);

						for (let i = 0; i < this.greenList.length; i++) {
							const item = this.greenList[i];

							if (listId.length === 0) {
								break;
							}

							const findIndex = listId.findIndex((option) => option === +item.id);

							if (findIndex > -1) {
								item.verified = 3;

								listId.splice(findIndex, 1);
							}
						}

						this.loadingActionSelection = false;
						this.createToastForMobile(this.FormMSG(951, 'Success'), this.FormMSG(953, 'Data is reviewed!'));
					} catch (e) {
						this.loadingActionSelection = false;
						this.error = e;
					}
				};

				this.confirmModal(action, this.FormMSG(206, "Confirm changing status of selected items to 'REVIEWED'?"));
			} catch (e) {
				this.loadingForMarkReview = false;
				this.createToastForMobile(this.FormMSG(1612, 'Error'), this.FormMSG(1613, 'Error during update data'), '', 'danger');
			}
		},

		async handleResetFilter() {
			this.filterInputModel = '';
			this.filter = '';

			this.offset = 0;
			this.pagination.offset = 0;
			this.alreadyLastData = false;
			await this.getGreenTableList();
		},

		handleKeydowEnterFilter() {
			document.getElementById('groupAppendSearch').focus();
			// this.handleChangeFilter(this.filterInputModel);
		},

		async handleChangeFilter(payload) {
			this.filter = payload;

			this.offset = 0;
			this.pagination.offset = 0;
			this.alreadyLastData = false;
			this.greenListToReview = [];
			await this.getGreenTableList();
		},

		async handleNewEntryOptionSuccess({ carbons }) {
			this.offset = 0;
			this.pagination.offset = 0;
			this.alreadyLastData = false;

			if (carbons.length > 0) {
				this.greenListToReview = carbons;
				const item = carbons[0];
				this.prepareDataToReview(item, 1);
				this.getIndexFromListToReview = true;
				this.currentIndexDataToReview = 1;
				this.lengthDataToReview = carbons.length;
				this.showReviewDialog = true;
			}

			await this.getGreenTableList();

			this.showNewEntryOptionDialog = false;
		},

		async handleViewLogAutomation() {
			this.showViewLogAutomation = false;
			this.logAutomation = {};

			this.offset = 0;
			this.pagination.offset = 0;
			this.alreadyLastData = false;
			await this.getGreenTableList();
		},

		async handleClickRunAutomation() {
			this.showRunAutomationOption = true;
		},

		async runAutomationAction(choice) {
			this.loadingRunAutomation = true;

			console.log({ choice, data: this.greenListToReview });
			alert('HERE');
			let listId = [];
			if (this.greenListToReview.length > 0) {
				for (let i = 0; i < this.greenListToReview.length; i++) {
					const element = this.greenListToReview[i];
					listId.push(+element.id);
				}
			} else {
				listId = null;
			}

			this.logAutomation = await runAutomation(null, choice === 1, listId);
			this.showViewLogAutomation = true;

			this.loadingRunAutomation = false;
		},

		async checkIfCarbonTemplateExist() {
			this.carbonTemplateExist = await checkIfCarbonTemplateExist();
		},

		async handleClickDeleteExternalSources() {
			const action = async () => {
				let listId = [];
				for (let i = 0; i < this.greenListToReview.length; i++) {
					const element = this.greenListToReview[i];
					if (+element.entityType === 8) {
						listId.push(+element.entityId);
					}
				}

				if (listId.length > 0) {
					await delCarbonFromExternalSource(listId);

					this.greenList = this.greenList.filter((option) => !listId.includes(+option.entityId));
					this.greenListToReview = [];
					this.selectAll = false;
					this.greenList = this.greenList.map((option) => ({
						...option,
						checked: false
					}));
					this.createToastForMobile(this.FormMSG(98, 'Success'), this.FormMSG(917, 'Externals deleted successfully!'));
				} else {
					this.createToastForMobile(
						this.FormMSG(98, 'Success'),
						this.FormMSG(1250, 'No external found'),
						this.FormMSG(1252, 'No external is found in the list'),
						'warning'
					);
				}
			};

			this.confirmModal(action, this.FormMSG(1421, 'Only entries from external sources will be deleted. Are you sure you want to delete it?'));
		},

		handleChangePagination({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;

			this.getGreenTableList(true);
		},

		async handleClickDeleteExternal(item) {
			const action = async () => {
				await delCarbonFromExternalSource([+item.entityId]);

				this.greenList = this.greenList.filter((option) => option.id !== item.id);
				this.greenListToReview = this.greenListToReview.filter((option) => option.id !== item.id);

				this.createToastForMobile(this.FormMSG(98, 'Success'), this.FormMSG(121, 'External deleted successfully!'));
			};

			this.confirmModal(action, this.FormMSG(97, 'Are you sure ?'));
		},

		handleClickReviewSelection() {
			const item = this.greenListToReview[0];
			this.prepareDataToReview(item, 1);
			this.getIndexFromListToReview = true;
			this.currentIndexDataToReview = 1;
			this.lengthDataToReview = this.greenListToReview.length;
			this.showReviewDialog = true;
		},

		handleInputItemChecked(payload, item, index) {
			let findIndex = -1;
			if (this.greenListToReview.length > 0) {
				findIndex = this.greenListToReview.findIndex((option) => parseInt(option.id, 10) === parseInt(item.id, 10));
			}

			if (findIndex > -1) {
				this.greenListToReview.splice(findIndex, 1);

				if (this.greenListToReview.length === 0) {
					this.selectAll = false;
				}
			} else if (findIndex === -1) {
				this.greenListToReview.push({
					...item,
					indexFromList: index
				});
				this.selectAll = true;
			}
		},

		handleInputSelectAll(value) {
			this.greenListToReview = [];

			this.greenList = this.greenList.map((option) => {
				const result = {
					...option,
					checked: value
				};
				if (value) {
					this.greenListToReview.push(result);
				}

				return result;
			});
		},
		handleReviewDialogReviewed({ currentIndex }) {
			this.greenListToReview[currentIndex].verified = 3;

			for (let i = 0; i < this.greenList.length; i++) {
				if (+this.greenList[i].id === +this.greenListToReview[currentIndex].id) {
					this.greenList[i].verified = 3;
					break;
				}
			}

			this.handleReviewDialogAfter({ currentIndex });
		},

		async handleReviewDialogSubmit({ carbonData }) {
			this.offset = 0;
			this.alreadyLastData = false;
			this.totalRecord += 1;
			// await this.getGreenTableList();

			this.greenList.unshift(carbonData);
		},

		async handleReviewDialogUpdateSuccess({ carbonData }) {
			for (let i = 0; i < this.greenList.length; i++) {
				if (+this.greenList[i].id === +carbonData.id) {
					this.greenList[i] = carbonData;
					break;
				}
			}
		},

		handleReviewDialogAfter({ currentIndex }) {
			if (this.getIndexFromListToReview === false) {
				if (currentIndex + 1 === this.greenList.length) {
					const item = this.greenList[currentIndex];

					this.prepareDataToReview(item, currentIndex);
				} else {
					const item = this.greenList[currentIndex + 1];

					this.prepareDataToReview(item, currentIndex + 1);
				}
			} else {
				if (currentIndex + 1 === this.greenListToReview.length) {
					const item = this.greenListToReview[currentIndex];

					this.prepareDataToReview(item, currentIndex);
				} else {
					const item = this.greenListToReview[currentIndex + 1];

					this.prepareDataToReview(item, currentIndex + 1);
				}
			}
		},

		handleReviewDialogBefore({ currentIndex }) {
			if (this.getIndexFromListToReview === false) {
				const item = this.greenList[currentIndex - 1];

				this.prepareDataToReview(item, currentIndex - 1);
			} else {
				const item = this.greenListToReview[currentIndex - 1];

				this.prepareDataToReview(item, currentIndex - 1);
			}
		},

		handleClickNewEntry() {
			this.showNewEntryOptionDialog = true;
		},

		handleNewEntryOptionCreate() {
			this.showNewEntryOptionDialog = false;
			this.sourceType = 8;
			this.createNewGreen = true;
			this.dataToReview = null;
			this.showReviewDialog = true;
		},

		async handleClickReview(item, index) {
			await this.changeCheckedGreenList().then((res) => {
				this.prepareDataToReview(item, index);

				this.selectAll = false;
				this.greenListToReview = [{ ...item, indexFromList: index }];
				this.getIndexFromListToReview = true;
				this.currentIndexDataToReview = 1;
				this.lengthDataToReview = this.greenListToReview.length;
				this.showReviewDialog = true;

				this.greenList = res;
			});
		},
		changeCheckedGreenList() {
			return new Promise((resolve) => {
				if (this.selectAll === false) {
					resolve(this.greenList);
				} else {
					const result = this.greenList.map((option) => ({
						...option,
						checked: false
					}));

					resolve(result);
				}
			});
		},
		prepareDataToReview(item, index) {
			this.sourceType = parseInt(item.entityType, 10);

			this.currentIndexDataToReview = index + 1;
			this.createNewGreen = false;
			this.dataToReview = item;
		},

		async handleReviewDialogClose({ refreshList, currentIndex, carbonData }) {
			this.createNewGreen = false;
			this.sourceType = 1;
			this.dataToReview = {};
			this.currentIndexDataToReview = 0;
			// this.getIndexFromListToReview = false;
			// this.greenListToReview = []
			this.showReviewDialog = false;

			if (refreshList === true) {
				await this.getGreenTableList();
			}

			if (this.greenListToReview.length === 1) {
				this.greenList[this.greenListToReview[0].indexFromList].checked = false;
				this.greenListToReview = [];
				this.getIndexFromListToReview = false;
				this.selectAll = false;
			} else {
				this.greenList = this.greenList.map((option) => {
					return {
						...option,
						checked: false
					};
				});
				this.greenListToReview = [];
				this.getIndexFromListToReview = false;
				this.selectAll = false;
			}
		},

		async handleFilterBudgetAdvancedChange(payload) {
			this.filterActive = payload;
			if (!payload.startDate && !payload.endDate) {
				// this.filterActive.startDate = store.state.myProject.startDate;
				// this.filterActive.endDate = store.state.myProject.endDate;
				this.filterActive.startDate = null;
				this.filterActive.endDate = null;
			}
			if (payload.startDate && !payload.endDate) {
				// this.filterActive.startDate = payload.startDate;
				// this.filterActive.endDate = store.state.myProject.endDate;
				this.filterActive.startDate = payload.startDate;
				this.filterActive.endDate = null;
			}
			if (!payload.startDate && payload.endDate) {
				// this.filterActive.startDate = store.state.myProject.startDate;
				// this.filterActive.endDate = payload.endDate;
				this.filterActive.startDate = null;
				this.filterActive.endDate = payload.endDate;
			}
			if (payload.startDate && payload.endDate) {
				this.filterActive.startDate = payload.startDate;
				this.filterActive.endDate = payload.endDate;
			}
			if (!payload.userDepartment) {
				this.filterActive.userDepartment = -1;
			}

			this.offset = 0;
			this.pagination.offset = 0;
			this.alreadyLastData = false;
			this.greenListToReview = [];
			await this.getGreenTableList();
		},

		async getGreenTableList(toPush = false, containerToLoaded = 'containerGreenList') {
			const actionForLoader = async () => {
				let reviewStatus = [];
				this.getIndexFromListToReview = false;
				this.greenListToReview = [];

				if (!_.isNil(this.filterGreenTable.reviewStatus) && this.filterGreenTable.reviewStatus.length > 0) {
					this.filterGreenTable.reviewStatus.map((option) => {
						reviewStatus.push({ status: +option });
					});
				}

				let newFilterActive = {
					...this.filterGreenTable,
					recordOffSet: this.offset,
					recordLimit: this.limit,
					keyWord: this.filter
				};

				if (reviewStatus.length > 0) {
					newFilterActive = _.assign(newFilterActive, {
						reviewStatus
					});
				}

				this.totalRecord = await getTotalNumberProjectCarbon({ ...this.filterGreenTable, reviewStatus });

				if (toPush === false) {
					this.pagination.offset = 0;
					this.setPaginationOffset();
					this.greenList = await getProjectCarbon(newFilterActive);
				} else if (toPush === true) {
					this.setPaginationOffset();
					const res = await getProjectCarbon(newFilterActive);

					if (res.length > 0) {
						this.greenList = _.concat(this.greenList, res);
					} else {
						this.alreadyLastData = true;
					}
				}
			};

			await this.$nextTick(async () => {
				console.log(this.$refs[containerToLoaded]);
				await this.showLoader(actionForLoader, containerToLoaded);
			});
		},
		setPaginationOffset() {
			if (this.totalRecord < LIMIT) {
				this.pagination.offset = this.totalRecord;
			} else {
				if (this.pagination.offset + LIMIT > this.totalRecord) {
					this.pagination.offset = this.totalRecord;
				} else {
					this.pagination.offset += LIMIT;
				}
			}
		},
		async showPicture(carbonId) {
			const files = await getCarbonFiles(carbonId);
			// console.log({ files });
			files.map((file) => ({
				xid: getFileName(file),
				src: `${process.env.VUE_APP_GQL}/images/${file}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + file,
				ext: getFileExtension(file)
			}));

			this.$previewImages({
				images: files,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		}
	}
};
</script>

<style scoped></style>
