<template>
	<div>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(52, 'Cost center')">
					<b-form-input :value="budget.costCenter" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(75, 'Name')">
					<b-form-input :value="budget.description" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>

			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(53, 'Type')">
					<b-form-input :value="typeValue" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(54, 'Budget amount')">
					<b-input-group>
						<b-form-input :value="budget.initialBudget" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text>
								<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(55, 'Estimated final cost (EFC)')">
					<b-input-group>
						<b-form-input :value="budget.estimatedFinalCost" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text>
								<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(165, 'Department')">
					<b-input-group>
						<b-form-input :value="budget.departmentName" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text>
								<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CurrencySvg from '@/components/Svg/Currency';
import _ from 'lodash';

export default {
	name: 'GreenBudgetDetailsForm',

	mixins: [languageMessages, globalMixin],

	components: { CurrencySvg },

	props: {
		dataToEdit: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			budget: {}
		};
	},

	watch: {
		dataToEdit: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.budget = _.cloneDeep(newVal);
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		typeValue() {
			if (this.budget.type === 0) {
				return this.FormMSG(1000, 'Expense');
			} else if (this.budget.type === 1) {
				return this.FormMSG(1001, 'Salary');
			}
		}
	}
};
</script>
