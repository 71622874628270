var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "reviewDialog",
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-backdrop": "",
        "ok-only": !_vm.isUpdateAndExternal,
        "ok-title": _vm.okTitleLabel,
        "ok-variant": _vm.okVariant,
        "ok-disabled": _vm.loadingForMarkReview || _vm.loadingForSubmit,
        "cancel-disabled": _vm.loadingForSubmit,
        "cancel-variant": "outline-primary",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
        show: _vm.emitEventShow,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.titleCaption) + "\n\t\t\t"
                    ),
                  ]),
                  _vm.isCreate === false && _vm.dataLength > 1
                    ? _c(
                        "b-col",
                        { staticClass: "pl-5", attrs: { cols: "4" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center",
                            },
                            [
                              _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-transparent text-color-rhapsody-in-blue",
                                    attrs: {
                                      disabled:
                                        _vm.disableBtnBefore ||
                                        _vm.loadingForMarkReview ||
                                        _vm.loadingForSubmit,
                                    },
                                    on: { click: _vm.handleClickBefore },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("ChevronLeft"), {
                                      tag: "component",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-color-rhapsody-in-blue fs-16 fw-40",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(45, "Entry")) +
                                        " " +
                                        _vm._s(_vm.currentIndex) +
                                        " / " +
                                        _vm._s(_vm.dataLength)
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-transparent text-color-rhapsody-in-blue",
                                    attrs: {
                                      disabled:
                                        _vm.disableBtnNext ||
                                        _vm.loadingForMarkReview ||
                                        _vm.loadingForSubmit,
                                    },
                                    on: { click: _vm.handleClickAfter },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("ChevronRight"), {
                                      tag: "component",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        disabled:
                          _vm.loadingForSubmit || _vm.loadingForRunAutomate,
                        block: "",
                      },
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(43, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        variant: "custom-primary-blue",
                        disabled:
                          _vm.loadingForSubmit || _vm.loadingForRunAutomate,
                      },
                      on: { click: _vm.handleClickRunAutomation },
                    },
                    [
                      _vm.loadingForRunAutomate
                        ? _c("b-spinner", {
                            staticClass: "mr-3",
                            attrs: { small: "" },
                          })
                        : _vm._e(),
                      !_vm.loadingForRunAutomate
                        ? _c(_vm.getLucideIcon("Play"), {
                            tag: "component",
                            attrs: { size: 16 },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(197, "Run automations")) +
                          "\n\t\t\t"
                      ),
                    ],
                    1
                  ),
                  !_vm.forCreate
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mr-3",
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            size: "md",
                            variant: "success",
                            disabled:
                              _vm.loadingForSubmit || _vm.loadingForRunAutomate,
                          },
                          on: { click: _vm.handleClickCreateCarbonTemplate },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  168,
                                  "Set as template for new automation"
                                )
                              ) +
                              "\n\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant: _vm.okVariant,
                        disabled:
                          _vm.loadingForSubmit || _vm.loadingForRunAutomate,
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _vm.loadingForSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.loadingForSubmit ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.okTitleLabel))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          ref: "content-review-dialog",
          attrs: { id: "content-review-dialog" },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c(
                "b-col",
                {
                  staticClass: "text-center",
                  attrs: { sm: "12", md: "4", lg: "4", xl: "4" },
                },
                [
                  _vm.imageUrls.length > 0
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: { variant: "primary" },
                          on: { click: _vm.onPickFile },
                        },
                        [
                          _c(_vm.getLucideIcon("Plus"), {
                            tag: "component",
                            attrs: { size: 16 },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.FormMSG(861, "Add picture")) +
                              "\n\t\t\t\t"
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    { attrs: { variant: "success", disabled: "" } },
                    [
                      _c(_vm.getLucideIcon("Camera"), {
                        tag: "component",
                        attrs: { size: 16 },
                      }),
                      _vm._v(" " + _vm._s(_vm.imageUrls.length) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", { attrs: { sm: "12", md: "8", lg: "8", xl: "8" } }, [
                _c("div", { staticClass: "float-right" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "wrap-status" }, [
                      _c(
                        "div",
                        {
                          staticClass: "status fw-700",
                          class: {
                            validated: _vm.carbonData.verified === 3,
                            "not-submitted": _vm.carbonData.verified === 0,
                            pending: _vm.carbonData.verified === 2,
                            info: _vm.carbonData.verified === 1,
                          },
                          staticStyle: { "font-size": "0.7rem" },
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.getLabelVerifiedStatus(
                                    _vm.carbonData.verified
                                  )
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm.showContextStatus
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex justify-content-center",
                            staticStyle: { "min-width": "60px" },
                          },
                          [
                            _c(
                              "b-dropdown",
                              {
                                staticClass: "btn-transparent",
                                attrs: {
                                  id: "dropdown-green-action",
                                  "no-caret": "",
                                  dropleft: "",
                                  boundary: "window",
                                  variant: "none",
                                  size: "sm",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _c(
                                            _vm.getLucideIcon("MoreVertical"),
                                            {
                                              tag: "component",
                                              attrs: { size: 16 },
                                            }
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1745820246
                                ),
                              },
                              [
                                _vm.showResetStatusMenu
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: _vm.handleClickResetStatus,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-color-rhapsody-in-blue",
                                          },
                                          [
                                            _c(_vm.getLucideIcon("RotateCcw"), {
                                              tag: "component",
                                              attrs: { size: 16 },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    289,
                                                    "Reset status"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.carbonData.templateId > 0
                                  ? _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickViewAutomation(
                                              _vm.carbonData.templateId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-color-rhapsody-in-blue",
                                          },
                                          [
                                            _c(_vm.getLucideIcon("Regex"), {
                                              tag: "component",
                                              attrs: { size: 16 },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    291,
                                                    "View automation"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "perfect-scrollbar",
            { attrs: { id: "content-review-dialog-details" } },
            [
              _c(
                "b-row",
                [
                  _vm.imageUrls.length > 0
                    ? _c(
                        "b-col",
                        {
                          staticClass: "details-document-package",
                          attrs: { sm: "12", md: "4", lg: "4", xl: "4" },
                        },
                        [
                          _c(
                            "carousel",
                            {
                              attrs: {
                                "pagination-enabled": false,
                                "per-page": 1,
                                "navigation-enabled": "",
                                "autoplay-direction": "backward",
                              },
                            },
                            _vm._l(_vm.imageUrls, function (img, i) {
                              return _c(
                                "slide",
                                {
                                  key: i,
                                  attrs: {
                                    "data-log": _vm.$log({
                                      imageUrls: _vm.imageUrls,
                                    }),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "doc-list-package size-2",
                                      staticStyle: {
                                        height: "100%",
                                        width: "100%",
                                        position: "relative",
                                      },
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.handleMouseEnter(img, i)
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.handleMouseLeave(img, i)
                                        },
                                      },
                                    },
                                    [
                                      img.extension !== "pdf"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "doc-list-package-action",
                                              staticStyle: {
                                                "max-height": "100%",
                                                width: "100%",
                                              },
                                              style: `height: ${
                                                img.hoverAction ? "100%" : "0"
                                              }`,
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "container-doc-list-package-action",
                                                  class: {
                                                    "d-block": img.hoverAction,
                                                    "d-none": !img.hoverAction,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-transparent",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openNewTab(
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "Eye"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                size: 22,
                                                                "stroke-width": 1.5,
                                                                color:
                                                                  "#00A09C",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-transparent text-color-burning-tomato",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDeletePicture(
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "Trash2"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                size: 22,
                                                                "stroke-width": 1.5,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.forCreate === true
                                        ? _c("div", [
                                            ["jpg", "jpeg", "png"].includes(
                                              img.extension
                                            )
                                              ? _c("img", {
                                                  staticClass:
                                                    "location-slide-img",
                                                  staticStyle: {
                                                    height: "100% !important",
                                                    width: "100% !important",
                                                  },
                                                  attrs: {
                                                    src: img.imgSrc,
                                                    alt: img.imgSrc,
                                                  },
                                                })
                                              : _c("img", {
                                                  staticClass:
                                                    "location-slide-img",
                                                  staticStyle: {
                                                    height: "100% !important",
                                                    width: "100% !important",
                                                  },
                                                  attrs: {
                                                    src: "img/thumb-not-found.png",
                                                    alt: img.imgSrc,
                                                  },
                                                }),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              style:
                                                img.extension === "pdf"
                                                  ? "width: 100%; height: 100%"
                                                  : "",
                                            },
                                            [
                                              ["jpg", "jpeg", "png"].includes(
                                                img.extension
                                              )
                                                ? _c("div", [
                                                    _c("img", {
                                                      staticClass:
                                                        "location-slide-img",
                                                      staticStyle: {
                                                        height:
                                                          "100% !important",
                                                        width:
                                                          "100% !important",
                                                      },
                                                      attrs: {
                                                        src: img.imgSrc,
                                                        alt: img.imgSrc,
                                                      },
                                                    }),
                                                  ])
                                                : img.extension === "pdf"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        height: "100%",
                                                        "padding-bottom":
                                                          "35px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-overlay",
                                                        {
                                                          staticStyle: {
                                                            height: "100%",
                                                          },
                                                          attrs: {
                                                            show: img.loading,
                                                            opacity: "0.85",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "overlay",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center bg-transparent",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "loading",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                active:
                                                                                  img.loading,
                                                                                loader:
                                                                                  "bars",
                                                                                color:
                                                                                  "#00A09C",
                                                                                width: 64,
                                                                              },
                                                                            on: {
                                                                              "update:active":
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    img,
                                                                                    "loading",
                                                                                    $event
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "loading-text",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    100,
                                                                                    "Please wait..."
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("iframe", {
                                                            staticClass:
                                                              "full full_h clearfix",
                                                            attrs: {
                                                              src: img.imgSrc,
                                                              frameborder: "0",
                                                            },
                                                            on: {
                                                              load: function (
                                                                $event
                                                              ) {
                                                                return _vm.onFrameLoaded(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        { staticClass: "mt-2" },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "float-right",
                                                              attrs: {
                                                                variant:
                                                                  "danger",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleDeletePicture(
                                                                      i
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  "Trash2"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    size: 22,
                                                                    "stroke-width": 1.5,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      899,
                                                                      "Remove file"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        height: "100%",
                                                        width: "100%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "lazy-container",
                                                              rawName:
                                                                "v-lazy-container",
                                                              value: {
                                                                selector: "img",
                                                              },
                                                              expression:
                                                                "{ selector: 'img' }",
                                                            },
                                                          ],
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "location-slide-img",
                                                            staticStyle: {
                                                              height:
                                                                "100% !important",
                                                              width:
                                                                "100% !important",
                                                            },
                                                            attrs: {
                                                              "data-src":
                                                                _vm.getImageName(
                                                                  img.xid
                                                                ),
                                                              "data-error":
                                                                "img/thumb-not-found.png",
                                                              "data-loading":
                                                                "img/brand/tenor.gif",
                                                              alt: "pict",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-col",
                        {
                          staticClass: "details-document-package",
                          attrs: { sm: "12", md: "4", lg: "4", xl: "4" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "doc-list-package size-2",
                              staticStyle: {
                                height: "100%",
                                width: "100%",
                                position: "relative",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "add-img",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  on: { click: _vm.onPickFile },
                                },
                                [
                                  _c("div", { staticClass: "center-content" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-color-rhapsody-in-blue",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("PlusCircle"), {
                                          tag: "component",
                                          attrs: { size: 20, color: "#00A09C" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                  _c("input", {
                    ref: "additional-detail-file",
                    staticStyle: { display: "none" },
                    attrs: { type: "file" },
                    on: { change: _vm.onFilePicked },
                  }),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: 8, lg: 8, xl: 8 } },
                    [
                      _c("main-detail-for-review", {
                        attrs: {
                          source: _vm.sourceType,
                          "data-to-review": _vm.dataToEdit,
                          "for-create": _vm.forCreate,
                          "disable-form": _vm.disableForm,
                        },
                        on: {
                          "main-detail-for-review:change":
                            _vm.handleMainDetailForReviewChange,
                        },
                      }),
                      _c("additional-detail", {
                        attrs: {
                          title: _vm.titleAdditionalDetail,
                          source: _vm.sourceType,
                          "for-create": _vm.disableForm,
                          "data-to-review": _vm.dataToEdit,
                        },
                        on: {
                          "additional-detail:change":
                            _vm.handleAdditionalDetailChange,
                          "additional-detail:image-picked":
                            _vm.handleAdditionalDetailImagePicked,
                        },
                      }),
                      _c(
                        "fieldset",
                        {
                          staticClass: "my-0 py-0 pb-0 mt-4",
                          class: `${
                            _vm.$screen.width <= 576
                              ? "card-inside"
                              : "scheduler-border"
                          }`,
                        },
                        [
                          _c(
                            "legend",
                            {
                              staticClass:
                                "text-color-rhapsody-in-blue-2 fw-400",
                              class: `${
                                _vm.$screen.width <= 576
                                  ? "card-inside"
                                  : "scheduler-border"
                              }`,
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.titleGreenDetail) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "pt-3 pb-4" },
                            [
                              _c("co-two-clap", {
                                ref: "coTwoClapRef",
                                attrs: {
                                  "parent-id": _vm.entityId,
                                  "entity-type": _vm.sourceType,
                                  "for-create": !_vm.isEditMode,
                                  "is-submit": _vm.submitCarbon,
                                  "external-data": _vm.dataForCarbon,
                                  "external-carbon-data": _vm.carbonData,
                                  "disable-transport-forms":
                                    _vm.disableTransportForms,
                                  "disable-accomodation-forms":
                                    _vm.disableAccomodationForms,
                                  "disable-carbon-type-selector":
                                    _vm.disableCarbonSelector,
                                  "use-address-transport-string":
                                    _vm.useAddressTransportString,
                                  "hide-distance-known-transport":
                                    _vm.hideDistanceKnownForExpenseMileage,
                                  "hide-premise-menu": _vm.forCreate === true,
                                },
                                on: {
                                  "co-two-clap:reviewed":
                                    _vm.handleClickReviewed,
                                  "co-two-clap:init-carbon":
                                    _vm.handleCoTwoClapInitCarbon,
                                  "co-two-clap:change":
                                    _vm.handleCoTwoClapChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.forCreate === false &&
                      _vm.dataToEdit.verified === false
                        ? _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "pl-4 pr-4",
                                      attrs: {
                                        variant: "primary",
                                        disabled:
                                          _vm.loadingForMarkReview ||
                                          _vm.loadingForSubmit,
                                      },
                                      on: { click: _vm.handleClickReviewed },
                                    },
                                    [
                                      _vm.loadingForMarkReview === false
                                        ? _c(_vm.getLucideIcon("CheckCircle"), {
                                            tag: "component",
                                            attrs: { size: 16 },
                                          })
                                        : _vm._e(),
                                      _vm.loadingForMarkReview === true
                                        ? _c("b-spinner", {
                                            attrs: { small: "" },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(48, "Mark as reviewed")
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("carbon-template-dialog", {
            attrs: {
              open: _vm.showCarbonTemplateDialog,
              "data-to-edit": _vm.carbonTemplateData,
              "raw-text-data": _vm.rawTextAutomation,
            },
            on: {
              "carbon-template-dialog:close":
                _vm.handleCarbonTemplateDialogClose,
              "carbon-template:submit-success":
                _vm.handleCarbonTemplateSubmitSuccess,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }