<template>
	<div>
		<b-row>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(87, 'Date')">
					<b-form-input :value="dateOfWaste" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(88, 'Department')">
					<b-form-input :value="dataToSend.departmentName" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(89, 'Location / Premise')">
					<b-form-input :value="dataToSend.locationName" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import moment from 'moment';

export default {
	name: 'GreenWasteForm',

	mixins: [languageMessages, globalMixin],

	props: {
		dataToEdit: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			dataToSend: {
				departmentName: '-',
				createdAt: new Date(),
				locationName: '-'
			}
		};
	},

	watch: {
		dataToEdit: {
			handler(newVal) {
				this.dataToSend.createdAt = newVal.createdAt ? newVal.createdAt : ' - ';
				this.dataToSend.departmentName = newVal.departmentName ? newVal.departmentName : ' - ';
				this.dataToSend.locationName = newVal.location.setName ? newVal.location.setName : ' - ';
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		dateOfWaste() {
			if (!_.isNil(this.dataToSend.createdAt)) {
				return moment(new Date(this.dataToSend.createdAt.split('T')[0])).format('dddd, DD MMMM YYYY');
			}
			return '';
		}
	}
};
</script>

<style scoped></style>
