<template>
	<div>
		<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
			<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
				{{ FormMSG(15, 'Main detail') }}
			</legend>
			<div class="pt-3 pb-4">
				<b-row>
					<b-col sm="12" md="3" lg="3" xl="3">
						<b-form-group :label="sourceLabel">
							<b-form-input :value="sourceCaption" class="bg-color-grams-hair" disabled />
						</b-form-group>
						<div v-if="sourceType === 8">
							<b-form-checkbox
								v-model="dataToSend.forForecastReportOnly"
								size="lg"
								:value="true"
								:unchecked-value="false"
								:disabled="disableForm"
								@change="handleChangeForForecastReportOnly"
							>
								<div class="d-flex align-items-center fs-14 text-color-rhapsody-in-blue fw-400">
									{{ FormMSG(89, 'For forecast report only') }}
								</div>
							</b-form-checkbox>
						</div>
					</b-col>
					<b-col sm="12" md="6" lg="6" xl="6">
						<b-form-group :label="descriptionLabel">
							<b-form-textarea
								v-model="dataToSend.description"
								:class="{ 'bg-color-grams-hair': disableDescription }"
								autofocus
								rows="2"
								:disabled="disableDescription"
								@change="handleDescriptionChange"
							/>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="3" lg="3" xl="3">
						<b-form-group :label="coTwoLabel">
							<b-input-group>
								<b-form-input v-model="dataToSend.coTwo" class="bg-color-grams-hair" disabled />
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(283, 'kg') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</fieldset>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { rendKgCo2 } from '@/shared/helpers';

const SOURCE_TYPES = ['', 'location', 'accomodation', 'expense'];

export default {
	name: 'MainDetailForReview',

	props: {
		source: { type: Number, default: 1, required: false },
		dataToReview: { type: Object, default: () => ({}), required: false },
		disableForm: { type: Boolean, default: false, required: false }
	},

	mixins: [languageMessages],

	data() {
		return {
			sourceType: 8,
			dataToSend: {
				entityType: '',
				description: '',
				coTwo: 0,
				forForecastReportOnly: false
			}
		};
	},

	watch: {
		dataToReview: {
			handler(newVal) {
				this.dataToSend.description = newVal.description;
				this.dataToSend.coTwo = newVal.carbon ? rendKgCo2(newVal.carbon.carbonValues.kgCoTwo) : 0;
				this.dataToSend.forForecastReportOnly = newVal.forForecastReportOnly;
			},
			immediate: true,
			deep: true
		},
		source: {
			handler(newVal) {
				this.sourceType = newVal;
			},
			deep: true,
			immediate: true
		}
	},

	computed: {
		disableDescription() {
			if (this.source === 1 && this.disableForm === true) {
				return false;
			}

			return this.disableForm;
		},
		sourceLabel() {
			return this.FormMSG(20, 'Source');
		},
		descriptionLabel() {
			switch (this.sourceType) {
				case 5:
					return this.FormMSG(25, 'Name / Description');
				default:
					return this.FormMSG(21, 'Description');
			}
		},
		coTwoLabel() {
			return this.FormMSG(22, 'CO2');
		},
		sourceCaption() {
			return this.GetTextFromMenuNumberAndMenuValue(11006, this.source);
		}
	},

	methods: {
		handleDescriptionChange() {
			this.emitChange();
		},
		handleChangeForForecastReportOnly(payload) {
			this.emitChange();
		},
		emitChange() {
			this.$emit('main-detail-for-review:change', this.dataToSend);
		},
		getSourceValue(value) {
			return this.GetTextFromMenuNumberAndMenuValue(11006, value);
		}
	}
};
</script>

<style scoped></style>
