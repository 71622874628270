<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(75, 'New entry options')"
		header-class="header-class-modal-doc-package"
		size="lg"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleSubmit"
	>
		<form ref="containerEntryOptionRef">
			<b-form-radio :value="0" v-model="optionChoice">
				<div class="fs-14 fw-700">
					{{ FormMSG(48, 'Without receipt') }}
				</div>
			</b-form-radio>
			<b-form-radio :value="1" v-model="optionChoice" class="mt-3">
				<div class="d-flex">
					<div class="fs-14 fw-700">{{ FormMSG(158, 'Main receipt') }} -</div>
					<div class="fs-14 fw-500 ml-1">
						{{ FormMSG(73, 'Create one entry per receipt/invoice') }}
					</div>
				</div>
			</b-form-radio>
			<b-form-radio :value="2" v-model="optionChoice" class="mt-3">
				<div class="d-flex">
					<div class="fs-14 fw-700">{{ FormMSG(101, 'Detailed import') }} -</div>
					<div class="fs-14 fw-500 ml-1">
						{{ FormMSG(83, 'Create one entry per line of each receipt/invoice') }}
					</div>
				</div>
			</b-form-radio>
			<b-collapse :visible="optionChoice === 1 || optionChoice === 2">
				<div v-if="optionChoice === 1 || optionChoice === 2" class="mt-3">
					<b-row>
						<b-col cols="10">
							<b-form-file v-model="filesToUpload" multiple />
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col cols="10">
							<b-form-checkbox v-model="runAutomation" switch size="lg">
								<div class="fs-16 fw-700">{{ FormMSG(514, 'Run automation following import') }}</div>
							</b-form-checkbox>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</form>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { importCarbonClapExternalSource } from '@/cruds/carbon.crud';
import { store } from '@/store';

const axios = require('axios').create();

export default {
	name: 'NewEntryOptionDialog',

	props: {
		open: { type: Boolean, default: false, required: false }
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			optionChoice: 0,
			loadingSubmit: false,
			runAutomation: false,
			filesToUpload: []
		};
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	methods: {
		async inserFiles() {
			let results = [];
			for (let i = 0; i < this.filesToUpload.length; i++) {
				const element = this.filesToUpload[i];
				let formData = new FormData();

				formData.append('uploadimage', element);
				formData.append('fileName', element.name);
				formData.append('fileType', element.type);
				formData.append('parentType', 'carbon_from_external_source');
				formData.append('parentId', 0);

				let urlStr = process.env.VUE_APP_GQL + '/upload';

				await this.$axios
					.$post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((xid) => {
						results.push(xid.split('.')[0]);
					});
			}

			return results;
		},
		async handleSubmit() {
			if (this.optionChoice === 0) {
				this.$emit('new-entry-option:create');
			} else {
				const actionForLoader = async () => {
					this.loadingSubmit = true;
					const docXid = await this.inserFiles();
					const resultImport = await importCarbonClapExternalSource(docXid, this.optionChoice === 2, this.runAutomation);

					this.loadingSubmit = false;
					this.$emit('new-entry-option:success', {
						carbons: resultImport
					});

					this.emitEventClose();
				};

				await this.showLoader(actionForLoader, 'containerEntryOptionRef');
			}
		},
		emitEventClose() {
			this.$emit('new-entry-option:close');
		}
	}
};
</script>
