<template>
	<div>
		<b-row>
			<b-col sm="6" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(152, 'Consumption date')">
					<b-input-group>
						<b-form-input :value="consumptionDateDisplay" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="cursor-pointer">
								<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="6" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(57, 'Type 1')">
					<b-form-input class="bg-color-grams-hair" :value="labelForType1" disabled />
				</b-form-group>
			</b-col>
			<b-col sm="6" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(58, 'Type 2')">
					<b-form-input class="bg-color-grams-hair" :value="labelForType2" disabled />
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(75, 'Country')">
					<b-form-input class="bg-color-grams-hair" :value="labelForCountry" disabled />
				</b-form-group>
			</b-col>
			<b-col sm="6" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(60, 'Filming area')">
					<b-input-group>
						<b-form-input class="bg-color-grams-hair" :value="valueForFilmingArea" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(259, 'm2') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="6" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(69, 'Filming days')">
					<b-form-input class="bg-color-grams-hair" :value="valueForFilmingDays" disabled />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import _ from 'lodash';
import moment from 'moment';

export default {
	name: 'GreenLocationForm',

	mixins: [languageMessages, globalMixin],

	props: {
		dataToEdit: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			location: {}
		};
	},

	watch: {
		dataToEdit: {
			handler(newVal) {
				console.log({ newVal });
				this.location = _.cloneDeep(newVal);
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		consumptionDateDisplay() {
			if (this.location.consumptionDate && !_.isNil(this.location.consumptionDate)) {
				return moment(new Date(this.location.consumptionDate)).format('DD MMMM YYYY');
			}

			return '';
		},
		labelForType1() {
			return this.GetTextFromMenuNumberAndMenuValue(1356, +this.location.type1);
		},
		labelForType2() {
			const menuNumber = !store.isPme() ? 1026 : 1322;
			return this.location.type ? this.GetTextFromMenuNumberAndMenuValue(menuNumber, +this.location.type) : '-';
		},
		labelForCountry() {
			return this.location.address ? this.location.address.country : '-';
		},
		valueForFilmingArea() {
			return this.location.filmingArea ? this.location.filmingArea : 0;
		},
		valueForFilmingDays() {
			return this.location.filmingDays ? this.location.filmingDays : 0;
		}
	},

	methods: {
		getLabel() {
			return this.GetTextFromMenuNumberAndMenuValue(1356, +this.location.type1);
		}
	}
};
</script>

<style scoped></style>
