var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "fieldset",
      {
        staticClass: "my-0 py-0 pb-0 mt-4",
        class: `${
          _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
        }`,
      },
      [
        _c(
          "legend",
          {
            staticClass: "text-color-rhapsody-in-blue-2 fw-400",
            class: `${
              _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
            }`,
          },
          [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.FormMSG(15, "Main detail")) + "\n\t\t"
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "pt-3 pb-4" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: _vm.sourceLabel } },
                      [
                        _c("b-form-input", {
                          staticClass: "bg-color-grams-hair",
                          attrs: { value: _vm.sourceCaption, disabled: "" },
                        }),
                      ],
                      1
                    ),
                    _vm.sourceType === 8
                      ? _c(
                          "div",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  size: "lg",
                                  value: true,
                                  "unchecked-value": false,
                                  disabled: _vm.disableForm,
                                },
                                on: {
                                  change: _vm.handleChangeForForecastReportOnly,
                                },
                                model: {
                                  value: _vm.dataToSend.forForecastReportOnly,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataToSend,
                                      "forForecastReportOnly",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dataToSend.forForecastReportOnly",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center fs-14 text-color-rhapsody-in-blue fw-400",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            89,
                                            "For forecast report only"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: _vm.descriptionLabel } },
                      [
                        _c("b-form-textarea", {
                          class: {
                            "bg-color-grams-hair": _vm.disableDescription,
                          },
                          attrs: {
                            autofocus: "",
                            rows: "2",
                            disabled: _vm.disableDescription,
                          },
                          on: { change: _vm.handleDescriptionChange },
                          model: {
                            value: _vm.dataToSend.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataToSend, "description", $$v)
                            },
                            expression: "dataToSend.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: _vm.coTwoLabel } },
                      [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.dataToSend.coTwo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataToSend, "coTwo", $$v)
                                },
                                expression: "dataToSend.coTwo",
                              },
                            }),
                            _c(
                              "b-input-group-append",
                              [
                                _c(
                                  "b-input-group-text",
                                  {
                                    staticClass:
                                      "bg-color-north-wind text-color-white",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(283, "kg")) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }